import IconEmptyInbox from 'src/icons/IconEmptyInbox'

const Empty = ({ title, subtitle = '' }) => {
  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <IconEmptyInbox className="h-40 fill-none" />
      <div className="text-md font-medium text-doubleNickel-gray-800">
        {title}
      </div>
      <div className="text-sm font-normal text-doubleNickel-gray-600">
        {subtitle}
      </div>
    </div>
  )
}

export default Empty
